import React from "react";
import "./App.scss";
import { BrowserRouter } from "react-router-dom";
import Content from "./components/layout/Content/Content";
import Footer from "./components/layout/Footer/Footer";
import Header from "./components/layout/Header/Header";

function App() {
  return (
    <BrowserRouter>
      <div className="App">
        <div className="container_custom">
          <Header />
          <Content />
          <Footer />
        </div>
      </div>
    </BrowserRouter>
  );
}

export default App;
