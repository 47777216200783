import axios, { AxiosRequestConfig, RawAxiosRequestHeaders, ResponseType } from "axios";
import { Category, PlainObject, Song, Songbook, Users } from "./models";

//const DOMAIN = "http://localhost";
const DOMAIN = "https://aryn-api.pony.co.ua";
//const PORT = 3456;
const PORT = 443;
//const apiUrl = `${DOMAIN}:${PORT}/`;
const apiUrl = `${DOMAIN}:${PORT}/api/v1/`;

const createEndpoint = (url: string): string => {
  return apiUrl + url;
};

const createRequestConfig = (
  params?: PlainObject,
  contentType?: string,
  responseType?: ResponseType
): AxiosRequestConfig => {
  const headers: RawAxiosRequestHeaders = {
    "Access-Control-Allow-Origin": "true",
    "Content-Type": contentType || "application/json"
  };
  const token = localStorage.getItem("Token");
  if (token) {
    headers["Authorization"] = "Bearer " + token;
  }
  const requestConfig: AxiosRequestConfig = {
    headers,
    responseType: responseType || "json"
  };
  params && (requestConfig.params = params);
  return requestConfig;
};

const get = async <T>(
  path: string,
  params?: PlainObject,
  contentType?: string,
  responseType?: ResponseType
): Promise<T> => {
  try {
    const result = await axios.get<T>(
      createEndpoint(path),
      createRequestConfig(params, contentType, responseType)
    );
    return result.data;
  } catch (error) {
    console.log("HTTP Request Error", error);
    return Promise.reject(error);
  } finally {
    // placeholder for Spinner.close()
  }
};

const patch = async <T>(
  path: string,
  body: Song
): Promise<T> => {
  try {
    const result = await axios.patch<T>(
      createEndpoint(path),
      body,
      createRequestConfig()
    );
    return result.data;
  } catch (error) {
    console.log("HTTP Request Error", error);
    return Promise.reject(error);
  } finally {
    // placeholder for Spinner.close()
  }
};

const post = async <T>(
  path: string,
  body: Song
): Promise<T> => {
  try {
    const result = await axios.post<T>(
      createEndpoint(path),
      body,
      createRequestConfig()
    );
    return result.data;
  } catch (error) {
    console.log("HTTP Request Error", error);
    return Promise.reject(error);
  } finally {
    // placeholder for Spinner.close()
  }
};

const deleteItem = async <T>(
  path: string,
  params?: PlainObject,
  contentType?: string,
  responseType?: ResponseType
): Promise<T> => {
  try {
    const result = await axios.delete<T>(
      createEndpoint(path),
      createRequestConfig(params, contentType, responseType)
    );
    return result.data;
  } catch (error) {
    console.log("HTTP Request Error", error);
    return Promise.reject(error);
  } finally {
    // placeholder for Spinner.close()
  }
};


class RestClient {

  static async login(body: { email: string; password: string }) {
    try {
      const result = await axios.post<{ token: string }>(
        createEndpoint("auth/login"),
        body,
        {
          headers: {
            "Access-Control-Allow-Origin": "true",
            "Content-Type": "application/json"
          },
          responseType: "json"
        }
      );
      return result.data;
    } catch (error) {
      console.log("HTTP Request Error", error);
      return Promise.reject(error);
    }
  }

  static async register(body: { email: string; password: string; firstName: string; lastName: string; }) {
    try {
      const result = await axios.post<{ token: string }>(
        createEndpoint("auth/registration"),
        body,
        {
          headers: {
            "Access-Control-Allow-Origin": "true",
            "Content-Type": "application/json"
          },
          responseType: "json"
        }
      );
      return result.data;
    } catch (error) {
      console.log("HTTP Request Error", error);
      return Promise.reject(error);
    }
  }

  static getCategoryList() {
    return get<Category[]>("categories");
  }

  static getSongList(songbookId: string, categoryId?: string, searchQuery?: string) {
    const params: PlainObject = { songbookId };
    categoryId && (params.categoryId = categoryId);
    searchQuery && (params.searchQuery = searchQuery);
    return get<Song[]>("songs", params);
  }

  static getSong(id: string) {
    return get<Song>(`songs/${id}`);
  }

  static getSongs(ids: number[]) {
    const query = ids.join(",");
    return get<Song[]>(`songs?ids=${query}`);
  }


  static updateSong(id: string, body: Song) {
    return patch<Song>(`songs/${id}`, body);
  }

  static addSong(body: Song) {
    return post<Song>("songs", body);
  }

  static deleteSong(id: any) {
    return deleteItem<any>(`songs/${id}`);
  }

  static getSongbookList(searchQuery: string) {
    const params: PlainObject = {
    };
    searchQuery && (params.searchQuery = searchQuery);
    return get<Songbook[]>("songbooks", params);
  }

  static getSongbook(id: string) {
    return get<Songbook>(`songbooks/${id}`);
  }

  static addSongBook(book: any) {
    //delete book.id;
    return post<any>("songbooks", book);
  }

  static editSongBook(book: any) {
    return patch<any>(`songbooks/${book.id}`, book);
  }
  static deleteSongBook(id: any) {
    return deleteItem<any>(`songbooks/${id}`);
  }

  static getUsers(searchQuery: string) {
    const params: PlainObject = {
    };
    searchQuery && (params.searchQuery = searchQuery);
    return get<Users[]>("users", params);
  }
  
  static getSongBooks(ownerId: number) {
    return get<any[]>(`songBooks/?ownerId=${ownerId}`);
  }

  static getSelections(groupId: string | number) {
    return get<any[]>(`selections/?groupId=${groupId}`);
  }
  static getSelectionGroups() {
    return get<any[]>("groups");
  }

  static getSelectionGroup(groupId: string | number) {
    return get<any>(`groups/${groupId}`);
  }

  static editSelectionGroup(group: any) {
    return patch<any>(`groups/${group.id}`, group);
  }

  static addSelectionGroup(selectionGroup: any) {
    return post<any>("groups", selectionGroup);
  }

  static deleteSelectionGroup(id: any) {
    return deleteItem<any>(`groups/${id}`);
  }

  static addSelection(selection: any) {
    delete selection.id;
    console.log(11111111111, selection);
    return post<any>("selections", selection);
  }


  static deleteSelection(id: number) {
    console.log("id tryign to delete", id);
    return deleteItem<any>(`selections/${id}`);
  }
  static editSelection(selection: any) {
    return patch<any>(`selections/${selection.id}`, selection);
  }

}

export default RestClient;
