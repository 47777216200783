import "./Features.scss";
import { IoDesktopOutline, IoMusicalNote, IoMusicalNotes, IoPeopleOutline, IoRefreshOutline, IoCloseCircleOutline } from "react-icons/io5";
import { motion } from "framer-motion";
import React, { useCallback, useEffect, useRef, useState } from "react";

import { useNavigate } from "react-router-dom";
import { Songbook } from "../../../@core/models";
import debounce from "lodash/debounce";
import RestClient from "../../../@core/restClient";

const Features = () => {

  const queryRef = useRef<any>();
  const [query, setQuery] = useState<string>("");
  const [selectedSongbookId, setSelectedSongbookId] = useState<number | null>(null);
  const [songbooks, setSongbooks] = useState<Songbook[]>([]);
  const [isOpen, setIsOpen] = useState(false);
  const navigate = useNavigate();

  const deferredSetQueryHandler = useCallback(debounce(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setQuery(event.target.value);
    }, 500
  ), []);

  useEffect(function () {
    (async () => {
      if (queryRef.current !== document.activeElement ||
        queryRef.current.value !== query
      ) {
        return;
      }
      try {
        const response = await RestClient.getSongbookList(query);
        console.log("getSongbookList response", response);
        setSongbooks(response || []);
      } catch (error) {
        console.log(error);
      }
    })();
  }, [query, isOpen]);

  const selectSongbook = (songbook: Songbook) => {
    queryRef.current.value = songbook.title;
    setQuery(songbook.title);
    setSelectedSongbookId(songbook.id);
    setSongbooks([]);
    navigate(`/songbook/${songbook.id}`);
    window.scrollTo(0, 0);
    setQuery("");
    setIsOpen(false);
  };
  // const submitSongbook = (e: any) => {
  //   e.stopPropagation();
  //   if (selectedSongbookId !== null) { // todo: check using incorrect id
  //     console.log(`navigate to /songbook/${selectedSongbookId}`);
  //     navigate(`/songbook/${selectedSongbookId}`);
  //     setQuery("");
  //     setIsOpen(false);
  //   } else {
  //     // todo: show notification
  //   }
  // };
  const openAgain = () => {
    queryRef.current.value = "";
    //setQuery("");
    //setSelectedSongbookId(null);
    setIsOpen(true);
    console.log("state of open", isOpen);
  };



  const listAnimation = {
    hidden: {
      y: 100,
      opacity: 0
    },
    visible: {
      y: 0,
      opacity: 1
    }
  };

  const usefulAnimation = {
    hidden: {
      y: 100,
      opacity: 0
    },
    visible: (custom: number) => ({
      y: 0,
      opacity: 1,
      transition: { delay: custom * 0.2 }
    })
  };


  return (
    <div>
      <motion.section
        initial="hidden" whileInView="visible" viewport={{ amount: 0.2 }}
        className="feature">
        <div className="feature-features">
          <div className="feature-features-listbox feature-list-1">
            <motion.ul variants={listAnimation} className="feature-list ">
              <li>
                <IoMusicalNote className="feature-list-icon" /> Необмежена кількість
              </li>
              <li><IoMusicalNote className="feature-list-icon" />Легкий пошук</li>
              <li><IoMusicalNote className="feature-list-icon" />Зручне редагування</li>
            </motion.ul>
          </div>
          <div className="feature-imgbox">
            <img className="feature-imgbox-img" src="images/laptop.png" alt="a photo of a laptop" />
            <div className="feature-imgbox-motion-circle">
              <div className="music-note_1">
                <IoMusicalNotes className="note-style" />
              </div>
              <div className="music-note_2">
                <IoMusicalNotes className="note-style" />
              </div>
              <div className="music-note_3">
                <IoMusicalNotes className="note-style" />
              </div>
              <div className="music-note_4">
                <IoMusicalNotes className="note-style" />
              </div>
            </div>
          </div>
          <div className="feature-features-listbox feature-list-2">
            <motion.ul variants={listAnimation} className="feature-list ">
              <li><IoMusicalNote className="feature-list-icon" />Створення підбірок</li>
              <li><IoMusicalNote className="feature-list-icon" />Режим екрану</li>
              <li><IoMusicalNote className="feature-list-icon" />Вибір тональності</li>
            </motion.ul>
          </div>
        </div>
        <div className="d-flex align-items-center text-center">
          <div className="box-open">
            <p className="box-open-text-1">Обери та відкрий будь який збірник</p>
            <p className="box-open-text-2">Переглядай без авторизації</p>
            <IoMusicalNote className="icon-1 note-style" />
            <IoMusicalNote className="icon-2 note-style" />
            <IoMusicalNote className="icon-3 note-style" />
            <IoMusicalNote className="icon-4 note-style" />
            <IoMusicalNote className="icon-5 note-style" />
            <div className="box-open-btn">
              <input className="box-open-btn-input" type="text"
                ref={queryRef}
                placeholder="Введіть назву збірника.."
                onChange={deferredSetQueryHandler}
                onClick={() => openAgain()}
              />
              {/* <button className="box-open-btn-button btn-mcolor" onClick={submitSongbook}>Відкрити</button> */}
              {songbooks?.length > 0 && isOpen &&
                <div className="select-container_two">
                  <IoCloseCircleOutline className="close_two"
                    onClick={() => setIsOpen(false)}
                  />
                  {songbooks?.map((songbook: Songbook, index: number) => (
                    <div className="select-item_two"
                      key={index}
                      onClick={() => selectSongbook(songbook)}
                    >
                      {songbook.title}
                    </div>
                  ))}
                </div>
              }
            </div>
          </div>
        </div>
        <motion.div
          initial="hidden" whileInView="visible" viewport={{ amount: 0.4 }}
          className="options-background">
          <div className="d-flex text-center justify-content-center">
            <motion.h4 custom={1} variants={usefulAnimation} className="options-title">Корисні можливості</motion.h4>
          </div>
          <motion.div custom={2} variants={usefulAnimation} className="d-flex options">
            <div className="options-box">
              <IoRefreshOutline className="options-icon" />
              <p>
                Миттєве <br />
                оновлення
              </p>
            </div>
            <div className="options-box options-box-hidden">
              <IoDesktopOutline className="options-icon" />
              <p>
                Режим <br />
                повного екрану
              </p>
            </div>
            <div className="options-box">
              <IoPeopleOutline className="options-icon" />
              <p>
                Робота <br />
                у команді
              </p>
            </div>
          </motion.div>
        </motion.div>
      </motion.section>
    </div>
  );
};

export default Features;