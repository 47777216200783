import React from "react";
import "./Hero.scss";

const Hero = () => {

  return (
    <div
      className="hero">
      <div className="hero-small-text">
        Завжди під рукою
      </div>
      <h1 className="title">Створи<br />Свій Власний<br />
        <span>Збірник Пісень</span>
      </h1>
      <div className="text-center">
        <button className="create-button">Створити</button>
      </div>
      <div className="hero-img-container">
        <div className="hero-img-group">
          <div className="hero-img-1">
            <img src="/images/home-band.jpg" alt="home-band" />
          </div>
          <div className="hero-img-2">
            <img src="/images/home-musician4.jpg" alt="home-musician4" />
          </div>
          <div className="hero-img-3">
            <img src="/images/home-musician3.jpg" alt="home-musician3" />
          </div>
          <div className="geo-form"></div>
        </div>
      </div>
    </div>
  );
};

export default Hero;