import React, { useCallback, useEffect, useState } from "react";
import "./LeftSidebar.scss";
import { IoSearchOutline } from "react-icons/io5";
import { Category, Filter, Song } from "../../../@core/models";
import RestClient from "../../../@core/restClient";
import { Link, useNavigate, useParams } from "react-router-dom";
import debounce from "lodash/debounce";

type CategoriesState = [
  categories: Category[],
  setCategories: (categories: Category[]) => void
];

type SongsState = [
  songs: Song[],
  setSongs: (songs: Song[]) => void
];

type FilterState = [
  filter: Filter,
  setFilter: ((arg: Filter | ((current: Filter) => Filter)) => void)
];

const LeftSidebar = ({ songListModified, closeSidebar }: any) => {

  const { songbookId, songId } = useParams();
  const [categories, setCategories]: CategoriesState = useState<Category[]>([]);
  const [songs, setSongs]: SongsState = useState<Song[]>([]);
  const [filter, setFilter]: FilterState = useState<Filter>({ category: "", query: "" });
  const navigate = useNavigate();

  const deferredSetQueryHandler = useCallback(debounce(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setFilter((current: Filter) => ({ ...current, query: event.target.value }));
    }, 500
  ), []);

  const getSongList = async () => {
    if (!songbookId) { return; }
    try {
      const response = await RestClient.getSongList(songbookId, filter.category, filter.query);
      console.log("getSongList response", response);
      setSongs(response || []);
      if (response?.length) {
        navigate(`/songbook/${songbookId}/song/${response[response.length - 1].id}`);
      } else {
        navigate(`/songbook/${songbookId}`);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    (async () => {
      try {
        const response = await RestClient.getCategoryList();
        console.log("getCategoryList response", response);
        setCategories(response || []);
      } catch (error) {
        console.log(error);
      }
    })();
  }, []);

  useEffect(() => {
    (async () => {
      await getSongList();
    })();
  }, [filter, songListModified]);

  useEffect(() => {
    (async () => {
      if (filter.category || filter.query) {
        setFilter({ category: "", query: "" });
      } else {
        await getSongList();
      }
    })();
  }, [songbookId]);

  return (
    <div className="left-sidebar">
      <div className="search-field">
        <input type="text" className="search-song"
          placeholder="Пошук за назвою ..."
          onChange={deferredSetQueryHandler}
        />
        <IoSearchOutline className="search-icon" />
      </div>
      <div className="topic">
        <select className="topic-field"
          value={filter.category}
          onChange={(event: React.ChangeEvent<HTMLSelectElement>) => {
            setFilter({ ...filter, category: event.target.value });
          }}
        >
          <option value={""}>Всі Категорії</option>
          {categories?.map((category: Category) => (
            <option value={category.id} key={category.id}>{category.name}</option>
          ))}
        </select>
      </div>
      <ul className="contents">
        {songs?.map((song: Song) => (
          <li key={song.id}
            className={Number(songId) === song.id ? "is-active" : ""}
            onClick={closeSidebar}
          >
            <Link to={`/songbook/${songbookId}/song/${song.id}`}>
              {song.title}
            </Link>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default LeftSidebar;
