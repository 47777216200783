import React, { useCallback, useEffect, useRef, useState } from "react";
import "./SelectSongBook.scss";
import { IoEnter, IoSearchOutline, IoCloseCircleOutline } from "react-icons/io5";
import RestClient from "../../../@core/restClient";
import { listenerCancelled } from "@reduxjs/toolkit/dist/listenerMiddleware/exceptions";
import debounce from "lodash/debounce";
import { Songbook } from "../../../@core/models";




const SelectSongBook = (props: any) => {

  const [songBookList, setSongBookList]: any = useState([]);
  const [selectVisible, setSelectVisible]: any = useState("");
  const [inputValue, setInputValue]: any = useState("");
  const [bookId, setBookId]: any = useState(null);
  const bookRef = useRef<any>();
 
  const deferredSetQueryHandler = useCallback(debounce(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setInputValue(event.target.value);
    }, 500
  ), []);

  useEffect(function() {
    (async () => {
      if (bookRef.current !== document.activeElement ||
        bookRef.current.value !== inputValue
    ) {
      return;
    }
      try {
        const list = await RestClient.getSongbookList(inputValue);
        console.log("getSongbookList response", list);
        setSongBookList(list || []);
      } catch (error) {
        console.log(error);
      }
    })();
  }, [inputValue, selectVisible]);

  // useEffect(() => {
  //   console.log("my useEffect");
  //   RestClient.getSelections(0)
  //   .then((data) => console.log("data", data));
  // }, []);

  const selectSongbook = (songbook: Songbook) => {
    bookRef.current.value = songbook.title;
    setInputValue(songbook.title);
    setBookId(songbook.id);
    localStorage.setItem("songbookId", String(songbook.id));
    setSelectVisible("");
    //setSongbooks([]);
    // setIsOpen(false); // todo: need to be discussed
  };
  
  useEffect(() => {
    props.passBookId(bookId);
  }, [bookId]);

    
  const clear = () => {
    setSelectVisible("visable");
    setInputValue("");
    bookRef.current.value = "";
  };

    return (
        <div className="select">
          <input 
          className="select-input" 
          onClick={clear} 
          ref={bookRef}
          onChange={deferredSetQueryHandler}          
          />
            <div className={selectVisible ||"select-box"}>
              {songBookList.map((book: any) => 
                <div key={book.id} className="select-box-item" onClick={() => selectSongbook(book)} >{book.title}</div>
                )            
              }
              <IoCloseCircleOutline className="select-close" onClick={() => setSelectVisible("")}/>
            </div>
            
          
          <IoSearchOutline className="select-search" />
          
                        
         </div> 
    );
};

export default SelectSongBook;