import React from "react";
import "./RightSidebar.scss";
import { IoVolumeMediumSharp, IoPlay, IoMusicalNotes, IoDocumentTextOutline } from "react-icons/io5";



const RightSidebar = ({ onCreate, openDeleteSong }: any) => {
  return (
    <div className="right-sidebar">
      <div className="right-sidebar-1">
        <div className="btn-mcolor text" onClick={onCreate}>Додати пісню</div>
        <div className="btn-plain text border" onClick={openDeleteSong} >Видалити пісню</div>
      </div>
      <div className="right-sidebar-2">
        <div className="btn-plain text">Тональність</div>
        {/* <div className="btn-plain text">У підбірку</div> */}
        {/* <div className="btn-plain text"> До обраного</div> */}
      </div>
      <div className="right-sidebar-3">
        <div className="note">Медіа та ноти</div>
        <div className="note-absent">Файли відсутні</div>

        <div className="text"><IoVolumeMediumSharp className="text-icon" />
          аудіо
        </div>
        <div className="text"><IoPlay className="text-icon" />
          відео
        </div>

      </div>
      <div className="right-sidebar-4">
        <div className="text"><IoDocumentTextOutline className="text-icon" />Гітара</div>
        <div className="text"><IoDocumentTextOutline className="text-icon" />Скрипка</div>
        <div className="text"><IoDocumentTextOutline className="text-icon" />Флейта</div>
        <div className="text"><IoDocumentTextOutline className="text-icon" />Фно</div>
        <div className="text"><IoMusicalNotes className="text-icon" />Мінус</div>
        <p className="btn-plain aditional-files">Завантажити</p>
      </div>
    </div>
  );
};

export default RightSidebar;