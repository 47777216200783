import React from "react";
import "./Footer.scss";

const Footer = () => {
  const openQuestionModal = () => {
    console.log("Open Question Modal");
  };

  const proposals = {
    href: "https://t.me/aryn_lyrics",
    target: "_blank"
  };
  return (
    <footer className="footer">
      <p>ARYN Lyrics є новим проектом, тому поспішайте наповнювати його збірниками та піснями</p>
      <hr />
      <div className="footer-authors">
        <div>
          Внести пропозиції або повідомити про помилки можна на нашому телеграм каналі. Тисніть <a href={proposals.href} target={proposals.target} rel="noreferrer" className="footer_link" >СЮДИ</a>

        </div>
        <div>
          Автори та розробники проекту <a href="/" className="footer_link">ARYN Lyrics</a> - Юлія та Олександр
        </div>
      </div>
    </footer>
  );
};

export default Footer;