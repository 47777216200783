import React, { useState, useEffect } from "react";
import "./../../../../src/index.scss";
import "./SongFullScreen.scss";
import draftToHtml from "draftjs-to-html";
import SongEditor from "../SongEditor/SongEditor";
import { IoCloseCircleOutline } from "react-icons/io5";
import { useDeprecatedAnimatedState } from "framer-motion";




const SongFullScreen = ({ selectedSongs, closeFullScreen }: any) => {

    const [layout, setLayout] = useState(false);


    return (
        <div className="full-screen">
            <div className="full-screen-inner" >
                {selectedSongs.map((item: any) =>
                    <div key={item.id} className="full-screen-inner-wrap">
                        <div className="title-line">
                            <div >
                                <p className="title-line-title">{item.title}</p>
                            </div>
                            <div className="title-line-inner" >
                                <p className="title-line-key">{item.key}</p>
                                <p className="title-line-tempo">tempo {item.tempo}, {item.time}</p>
                            </div>

                            <IoCloseCircleOutline onClick={closeFullScreen} className="close-icon" />

                        </div>
                        <div className="song-body">

                            <div dangerouslySetInnerHTML={{
                                __html: draftToHtml(JSON.parse(item.text))
                            }} className={!layout ? "song-body-text" : "song-body-text-horizontal"}
                            ></div>
                        </div>


                    </div>

                )}



            </div>

        </div>
    );
};
export default SongFullScreen;