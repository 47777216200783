import React, { useEffect, useMemo, useState } from "react";
import "./Songbook.scss";
import LeftSidebar from "../../components/layout/LeftSidebar/LeftSidebar";
import RightSidebar from "../../components/layout/RightSidebar/RightSidebar";
import SongEditor from "../../components/widgets/SongEditor/SongEditor";
import { Song, Songbook } from "../../@core/models";
import RestClient from "../../@core/restClient";
import { useParams } from "react-router-dom";
import draftToHtml from "draftjs-to-html";
import {
  IoArrowBackCircleOutline, IoArrowForwardCircleOutline, IoDesktopOutline,
  IoDownload, IoPencilOutline, IoPrint
} from "react-icons/io5";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import SongFullScreen from "../../components/widgets/UI/SongFullScreen";

type EditModeState = [
  editMode: boolean,
  setEditMode: (editMode: boolean) => void
];
type CreateModeState = [
  editMode: boolean,
  setEditMode: (editMode: boolean) => void
];

// type SongState = [
//   song: Song,
//   setSong: (song: Song) => void
// ];

type SongbookState = [
  songbook: Songbook | null,
  setSongbook: (songbook: Songbook) => void
];

interface ToggleSidebar {
  left: boolean;
  right: boolean;
}

type ToggleSidebarState = [
  openSidebar: ToggleSidebar,
  setOpenSidebar: (openSidebar: ToggleSidebar) => void
];

const DEFAULT_SONG = {

  title: "",
  songbookId: null,
  key: "",
  time: "",
  tempo: "",
  text: "",
  categories: []

};

const BRAKEPOINT = 992;

const SongbookPage = () => {

  const { songbookId, songId } = useParams();
  const [editMode, setEditMode]: EditModeState = useState(false);
  const [createMode, setCreateMode]: CreateModeState = useState(false);
  const [song, setSong] = useState<any>(DEFAULT_SONG);
  const [songbook, setSongbook]: SongbookState = useState<Songbook | null>(null);
  const [openSidebar, setOpenSidebar]: ToggleSidebarState = useState<ToggleSidebar>({
    left: window.innerWidth >= BRAKEPOINT,
    right: window.innerWidth >= BRAKEPOINT
  });
  const [screenMode, setScreenMode] = useState(false);
  const [forScreen, setForScreen] = useState<any[]>([]);
  const [songListModified, setSongListModified] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);


  const closeFullScreen = () => {
    setScreenMode(false);
  };

  const toggleSidebar = (sidebar: "left" | "right") => {
    console.log(sidebar);
    if (sidebar === "left") {
      setOpenSidebar({ ...openSidebar, left: !openSidebar.left });
    } else if (sidebar === "right") {
      setOpenSidebar({ ...openSidebar, right: !openSidebar.right });
    }
  };

  const create = () => {
    setCreateMode(true);
    setEditMode(true);
    setSong(DEFAULT_SONG);
    if (window.innerWidth <= 992)
      toggleSidebar("right");
    console.log("mode set", createMode, editMode, song);
  };

  const closeSidebar = () => {
    if (window.innerWidth <= BRAKEPOINT)
      toggleSidebar("left");
  };

  useEffect(() => {
    const matchMedia = window.matchMedia(`(min-width: ${BRAKEPOINT}px)`);
    const matchMediaEventHandler = (event: MediaQueryListEvent) => {
      setOpenSidebar({ left: event.matches, right: event.matches });
    };
    matchMedia.addEventListener("change", matchMediaEventHandler);
    return () => {
      matchMedia.removeEventListener("change", matchMediaEventHandler);
    };
  }, []);

  useEffect(() => {
    (async () => {
      try {
        if (!songbookId) { return; }
        const songbook = await RestClient.getSongbook(songbookId);
        console.log("getSongbook response", songbook);
        setSongbook(songbook || {});
        setEditMode(false);
      } catch (error) {
        console.log(error);
      }
    })();
  }, [songbookId, createMode]);

  useEffect(() => {
    (async () => {
      await getSong();
    })();
  }, [songId]);

  const getSong = async () => {
    try {
      if (!songId) { setCreateMode(false); return; }
      const data = await RestClient.getSong(songId);
      console.log("getSong response", data);
      const song = {
        id: data.id,
        title: data.title,
        songbookId: data.songbookId,
        key: data.key,
        time: data.time,
        tempo: data.tempo,
        text: data.text,
        categories: data.categories
      };
      setSong(song || {});
      setEditMode(false);
      setCreateMode(false);
      setForScreen([song]);
    } catch (error) {
      console.log(error);
    }
  };

  // useEffect(() => {
  //   setForScreen([song]);
  // }, [songId]);

  const saveSong = async (songId: any) => {
    if (createMode) {
      try {
        const addedSong = await RestClient.addSong({ ...song, songbookId: songbook?.id });
        console.log("updateSong response", addedSong);
        setSong(addedSong || {});
        setEditMode(false);
        setCreateMode(false);
        setSongListModified(true);
        setTimeout(() => { setSongListModified(false); }, 3000);
      } catch (error) {
        console.log(error);
      }

    } else {
      try {
        delete song.id;
        const updatedSong = await RestClient.updateSong(songId, song);
        console.log("updateSong response", updatedSong);
        setSong(updatedSong || {});
        setEditMode(false);
        setCreateMode(false);
      } catch (error) {
        console.log(error);
      }
    }
  };
  const openDeleteSong = () => {
    if (window.innerWidth <= 992) {
      toggleSidebar("right");
    }
    setDeleteModal(true);
    console.log(song.id);
  };

  const deleteSong = () => {
    try {
      (async () => {
        const deletedSong = await RestClient.deleteSong(song.id);
        if (deletedSong) {
          setDeleteModal(false);
          setSongListModified(true);
          setTimeout(() => setSongListModified(false), 300);
        }
      }

      )();
    } catch (error) {
      console.log("error");
    }

  };



  const toolsIcons = useMemo(() => [
    {
      icon: <IoDesktopOutline />, text: "На весь екран", action: () => {
        setScreenMode(true);
        console.log(forScreen);
      }
    },
    // { icon: <IoPrint />, text: "Роздрукувати", action: () => { return false; } },
    // { icon: <IoDownload />, text: "Завантажити", action: () => { return false; } },
    { icon: <IoPencilOutline />, text: "Редагувати", action: () => setEditMode(true) }
  ], []);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const renderTooltip = (props: any, text: string) => (

    <Tooltip {...props} className="tools-tooltip">{text}</Tooltip>
  );

  useEffect(() => {

    const content = document.getElementById("songBody");
    if (screenMode && content) {
      content.style.position = "fixed";

    }
    if (!screenMode && content) {
      content.style.position = "relative";
    }
  }, [screenMode]);

  const hideDeleteModal = () => {
    setDeleteModal(false);
  };



  useEffect(() => {

    if (deleteModal) {
      document.body.style.position = "fixed";

    }
    if (!deleteModal) {
      document.body.style.position = "relative";
    }
  }, [deleteModal]);

  useEffect(() => {
    console.log("editMode", editMode, "createMode", createMode);
  }, [editMode, createMode]);


  return (
    <div className="songbook">
      {
        screenMode ?
          <div>
            <SongFullScreen selectedSongs={forScreen} closeFullScreen={closeFullScreen} />
          </div>
          : null
      }
      <div className="title">
        <h2>{songbook?.title || "Не знайдено"}</h2>
      </div>
      <div className="content-row" id="songBody">
        <div className={`toggler left_${openSidebar.left ? "open" : "close"}`}
          onClick={() => toggleSidebar("left")}
        >
          {openSidebar.left ? <IoArrowBackCircleOutline /> : <IoArrowForwardCircleOutline />}
        </div>
        <div className={`left ${openSidebar.left ? "open" : "close"}`}>
          <LeftSidebar songListModified={songListModified} closeSidebar={closeSidebar} />
        </div>
        <div className="center">

          <>
            {!screenMode ?
              <div className="tools">
                {toolsIcons.map((item, index) => (
                  <OverlayTrigger
                    key={index}
                    placement="top"
                    overlay={(props) => renderTooltip(props, item.text)}
                  >
                    <button className="tool-icon"
                      onClick={item.action}
                    >
                      {item.icon}
                    </button>
                  </OverlayTrigger>
                ))}
              </div> : null
            }

            <div className="song">
              <div className="my-3">
                {editMode || createMode ? (
                  <input type="text" className="form-control"
                    value={song?.title}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => setSong(
                      { ...song, title: event.target.value }
                    )}
                  />
                ) : (
                  <h3>{song?.title}</h3>
                )}
              </div>
              <div className="data">
                <div className="key">

                  {editMode || createMode ? (
                    <p>Тональність: <input type="text" className="form-control"
                      value={song?.key}
                      onChange={(event: React.ChangeEvent<HTMLInputElement>) => setSong(
                        { ...song, key: event.target.value }
                      )}
                    /></p>
                  ) : (
                    <p> {songId ? "Тональність:" : ""}<span>{song?.key}</span></p>
                  )}
                </div>
                <div className="time">

                  {editMode || createMode ? (
                    <p> Розмір:<input type="text" className="form-control"
                      value={song?.time}
                      onChange={(event: React.ChangeEvent<HTMLInputElement>) => setSong(
                        { ...song, time: event.target.value }
                      )}
                    /></p>
                  ) : (

                    <p> {songId ? "Розмір:" : ""}<span>{song?.time}</span></p>
                  )}
                </div>
                <div className="tempo">

                  {editMode || createMode ? (
                    <p>Темп:<input type="text" className="form-control"
                      value={song?.tempo}
                      onChange={(event: React.ChangeEvent<HTMLInputElement>) => setSong(
                        { ...song, tempo: event.target.value }
                      )}
                    /></p>
                  ) : (
                    <p> {songId ? "Темп:" : ""}<span>{song?.tempo}</span></p>
                  )}
                </div>
              </div>
              <div className="text">
                {editMode || createMode ? (
                  <SongEditor
                    row={song?.text || ""}
                    onRowChange={(row) => setSong({ ...song, text: row })}
                  />
                ) : (
                  <div dangerouslySetInnerHTML={{
                    __html: (song && song.text) ? draftToHtml(JSON.parse(song.text)) : ""
                  }}
                  ></div>
                )}
              </div>
              {editMode || createMode ?
                <div className="edit-action">

                  <button className="btn-mcolor mx-3"
                    onClick={() => saveSong(songId)}
                  >
                    Зберегти
                  </button>
                  <button className="btn-plain mx-3"
                    onClick={getSong}
                  >
                    Скасувати
                  </button>
                </div>
                : null}
            </div>
          </>

        </div>
        <div className={`right ${openSidebar.right ? "open" : "close"}`}>
          <RightSidebar onCreate={create} openDeleteSong={openDeleteSong} />
        </div>
        <div className={`toggler right_${openSidebar.right ? "open" : "close"}`}
          onClick={() => toggleSidebar("right")}
        >
          {openSidebar.right ? <IoArrowForwardCircleOutline /> : <IoArrowBackCircleOutline />}
        </div>
      </div>
      {deleteModal ?
        <div className="delete">
          <div className="delete-box">
            <p className="delete-box-message">Видалити пісню <span>{song.title}</span> ?</p>
            <div className="delete-box-btns">
              <button className="btn-mcolor delete-box-btn " onClick={deleteSong}>Так</button>
              <button className="btn-plain delete-box-btn " onClick={hideDeleteModal}>Ні</button>
            </div>
          </div>
        </div>
        : null
      }
    </div>
  );
};

export default SongbookPage;


// {songId ? (
// ) : (
//   <h3 className="no-song my-3">Пісня не обрана</h3>
// )}