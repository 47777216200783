import React, { useState, useEffect, useRef } from "react";
import "./SLists.scss";
import ChooseSongs from "../../components/widgets/ChooseSongs/ChooseSongs";
import ShowSongs from "../../components/widgets/ChooseSongs/ShowSongs";
import { IoCloseCircle, IoArrowUpCircleSharp, IoDesktopOutline } from "react-icons/io5";
import SelectSongBook from "../../components/widgets/UI/SelectSongBook";
import SelectUsers from "../../components/widgets/UI/SelectUsers";
import RestClient from "../../@core/restClient";
import SongFullScreen from "./../../components/widgets/UI/SongFullScreen";
import draftToHtml from "draftjs-to-html";





const SLists = () => {

  const [selections, setSelections] = useState([]);
  const [selectionId, setSelectionId] = useState(null);
  const [groupsList, setGroupsList] = useState<any[]>([]);
  const [sListSettings, setsListSettings] = useState("hide-modal");
  const [chooseOption, setChooseOption] = useState("chooseGroup");
  const [btnDisabled, setBtnDisabled] = useState(true);
  const [chooseSongsModal, setChooseSongsModal] = useState("hide-modal");
  const [newGroupName, setNewGroupName] = useState("");
  const [collaboratorsList, setCollaboratorsList] = useState<any[]>([]);
  const [btnSaveDisabled, setBtnSaveDisabled] = useState(true);
  const [isNewGroupSent, setIsNewGroupSent] = useState(true);
  const [isSelectionSent, setIsSelectionSent] = useState(false);
  const [editedSelection, setEditedSelection] = useState({ id: 0, name: "", date: "", songs: [] });
  const [isCreate, setIsCreate] = useState(false);
  const [editedId, setEditedId] = useState(0);
  const [editMode, setEditMode] = useState(false);
  const [newGroupId, setNewGroupId] = useState(0);
  const [yesDelete, setYesDelete] = useState(false);

  const emptyRef = useRef();
  const refEdit = useRef();

  const newGroupNameHandler = function (event: any) {
    setNewGroupName(event.target.value);
    event.target.value !== "" ? setBtnSaveDisabled(false) : setBtnSaveDisabled(true);
  };

  const addCollaborators = function (collaborators: any) {
    console.log(collaborators);
    setCollaboratorsList(collaborators);

  };

  const savingNewGroup = () => {
    const creatorId = JSON.parse(localStorage.getItem("User") || "{}")?.id;
    if (!creatorId) return;
    const newGroup = {
      id: newGroupId,
      groupName: newGroupName,
      creatorId: creatorId,
      collaborators: collaboratorsList
    };

    console.log(newGroup);

    if (editMode) {
      setNewGroupName("");
      setEditMode(false);

    } else {
      setNewGroupName("");
      setChooseOption("chooseGroup");
      setEditMode(false);

    }
    setBtnSaveDisabled(true);


    if (emptyRef && emptyRef.current) (emptyRef.current as any).emptyCollaboratorsArray();
    setsListSettings("hide-modal");
    saveNewGroupHandler(newGroup);

  };

  const saveNewGroupHandler = (enteredItem: any) => {
    if (!editMode) {
      try {
        (async () => {
          delete enteredItem.id;
          const result = await RestClient.addSelectionGroup(enteredItem);
          console.log(result);
          setIsNewGroupSent(true);

        })();
      } catch (error) {
        console.log(error);
      }
    } else {
      try {
        (async () => {

          const result = await RestClient.editSelectionGroup(enteredItem);
          console.log(result);
          setIsNewGroupSent(true);
          setEditMode(false);
        })();
      } catch (error) {
        console.log(error);
      }

    }

  };



  const saveNewListHandler = (enteredItem: any) => {
    console.log("is Creat =", isCreate);
    if (!isCreate) {

      try {
        (async () => {
          const result = await RestClient.editSelection(enteredItem);
          console.log(result);
        })();
        setIsSelectionSent(true);
        console.log(isCreate);
      } catch (error) {
        console.log(error);
      }
    } else {
      try {
        (async () => {
          const result = await RestClient.addSelection(enteredItem);
          console.log(result);
        })();
        setIsSelectionSent(true);
        setIsCreate(false);

      } catch (error) {
        console.log(error);
      }
    }

  };

  const deleteGroup = (id: any) => {
    console.log("deleting group");
    try {
      (async () => {
        const groupDeleted = await RestClient.deleteSelectionGroup(id);
        if (groupDeleted) {
          setChooseOption("chooseGroup");
          setsListSettings("hide-modal");
          setSelectionId(null);
          setBtnDisabled(true);
          setSelections([]);
          setIsNewGroupSent(true);
          setYesDelete(false);
        }
      })();
    } catch (error) {
      console.log(error);
    }

  };

  const deleteSelection = (id: number) => {
    console.log("trying to delete");
    try {
      (async () => {
        const result = await RestClient.deleteSelection(id);
        console.log(result);
        if (result) { setSelections(selections.filter((item: any) => item.id !== id)); }
      })();
    } catch (error) {
      console.log(error);
    }
  };

  const editSelection = (id: number, name: string, date: any, songs: []) => {
    console.log(id, name, date, songs);
    const edited = {
      id: id,
      name: name,
      date: date,
      songs: songs
    };
    setEditedSelection(edited);

    setChooseSongsModal("");
    console.log(editedSelection);


  };
  useEffect(function () {
    if (refEdit && refEdit.current) (refEdit.current as any).edit();
    console.log((refEdit.current as any).edit);
    setEditedId(editedSelection.id);
  }, [editedSelection]);


  const chooseGroupHandler = (e: any) => {

    if (e.target.value === "create") {

      setsListSettings("");
      setChooseOption(e.target.value);
      setSelectionId(e.target.value);
      setBtnDisabled(true);

      console.log("id", selectionId, chooseOption);
      return;
    }

    if (e.target.value === "chooseGroup") {
      setsListSettings("hide-modal");
      setChooseOption(e.target.value);
      setSelectionId(e.target.value);
      setBtnDisabled(true);
      console.log("id", selectionId, chooseOption);
      return;
    }

    if (e.target.value !== "create" || e.target.value !== "chooseGroup")
      setSelectionId(e.target.value);
    setChooseOption(e.target.value);
    setBtnDisabled(false);
    setsListSettings("hide-modal");
    console.log("id", selectionId, chooseOption);
    console.log(sListSettings);
    return;

  };

  const editGroup = (id: any) => {
    console.log(id, chooseOption);
    try {
      (async () => {
        const group = await RestClient.getSelectionGroup(id);
        console.log(group);
        if (group) {
          setNewGroupName(group.groupName);
          setCollaboratorsList(group.collaborators);
          setEditMode(true);
          setsListSettings("");
          setBtnSaveDisabled(false);
          setNewGroupId(group.id);
        }
      })();

    } catch (error) {
      console.log(error);
    }
  };

  // const gettingBookId = (bookId: any) => {
  //   const selectedBook = bookId;
  // };

  const hideSettingsHandler = () => {

    if (editMode) {
      setsListSettings("hide-modal");
      setIsCreate(false);
      setEditMode(false);
      setBtnSaveDisabled(true);
      //setBtnDisabled(true);
      setNewGroupName("");
    } else {
      setsListSettings("hide-modal");
      setChooseOption("chooseGroup");
      setBtnDisabled(true);
      setNewGroupName("");
      setBtnSaveDisabled(true);
      setIsCreate(false);
      setEditMode(false);
    }

    if (emptyRef && emptyRef.current) (emptyRef.current as any).emptyCollaboratorsArray();
  };

  const displayChooseSongsModal = () => {
    setChooseSongsModal("");
    setIsCreate(true);
  };
  const hideChooseSongsModal = () => {
    setChooseSongsModal("hide-modal");
    setIsCreate(false);

  };

  const inactiveBtn = {
    background: "#999",
    transform: "scale(1)"


  };
  const inactiveOnhover = {
    boxSshadow: "none",
    transform: "none"
  };


  useEffect(function () {
    console.log("lalala2");
    (async () => {
      if (selectionId === null) {
        return;
      }
      try {
        const list: any = await RestClient.getSelections(selectionId);
        console.log("getSelections response", list);
        setSelections(list || []);
        setIsSelectionSent(false);
      } catch (error) {
        console.log(error);
      }
    })();
  }, [selectionId, isSelectionSent]);


  useEffect(function () {

    try {
      if (isNewGroupSent) {
        (async () => {
          const groupsList: any = await RestClient.getSelectionGroups();
          console.log("getGroupList response", groupsList);
          setGroupsList(groupsList || []);
          setIsNewGroupSent(false);
          return groupsList;
        })();
      }
    } catch (error) {
      console.log(error);
    }
  }, [isNewGroupSent]);

  console.log(selectionId);

  const sure = () => {
    setYesDelete(true);
  };
  const cansel = () => {
    setYesDelete(false);
  };


  return (
    <div className="slist">
      <div className="group-big-box">
        <div className="group-box">

          <p className="slist-setting-btn">Група: </p>
          <select onChange={chooseGroupHandler} value={chooseOption} className="slist-choose-group" name="create">
            <option value="chooseGroup">Вибрати групу</option>
            {groupsList.map(item => <option key={item.id} value={item.id} >{item.groupName}</option>)}
            <option value="create">Створити групу</option>
          </select>
        </div>
        {chooseOption === "create" || chooseOption === "chooseGroup" ? null :
          <div>
            <button className="btn-plain" onClick={() => editGroup(chooseOption)}>Редагувати</button>
            <button className="btn-plain" onClick={sure}>Видалити</button>
          </div>
        }
      </div>


      <div className={sListSettings || "settings-box"}>


        <p className="attributes-users">Введіть назву групи</p>
        <input type="text" className="create-group-title" value={newGroupName} onChange={newGroupNameHandler} />
        <p className="attributes-users">Введіть електронні адреси учасників</p>
        <div>
          <SelectUsers passingCollaboratorsArray={addCollaborators} ref={emptyRef} collaboratorsList={collaboratorsList} editMode={editMode} />

        </div>

        <div className="choose-btns">
          <button className={`${btnSaveDisabled ? "btn-mcolor-inactive" : "btn-mcolor"}`} disabled={btnSaveDisabled} onClick={savingNewGroup}>Зберегти</button>
          {/* <button className="btn-mcolor" onClick={savingNewGroup}>Зберегти</button> */}
          <button className="btn-plain" onClick={hideSettingsHandler}>Скасувати</button>
        </div>
      </div>
      <button className={`${btnDisabled ? "btn-mcolor-inactive slists-btn-creat" : "btn-mcolor slists-btn-create"}`}
        disabled={btnDisabled}
        onClick={displayChooseSongsModal}>Створити підбірку</button>
      <div className={chooseSongsModal}>
        <ChooseSongs onSavingSongList={saveNewListHandler} hideChooseSongsModal={hideChooseSongsModal} selectionId={selectionId} editedSelection={editedSelection} ref={refEdit} />
      </div>
      <div className="show-songs-margin">
        {selections.map((item: any) =>
          <ShowSongs id={item.id} name={item.name} date={item.date} songs={item.songList} key={item.id} onDelete={deleteSelection} editSelection={editSelection} />
        )};
        {/* <SongFullScreen selectedSongs={selections[0]} /> */}
      </div>



      {yesDelete ?
        <div className="delete">
          <div className="delete-box">
            <p className="delete-box-message">Bидалити групу?</p>
            <div className="delete-box-btns">
              <button className="btn-mcolor delete-box-btn " onClick={() => deleteGroup(chooseOption)}>Так</button> <button className="btn-plain delete-box-btn " onClick={cansel}>Ні</button>
            </div>
          </div>
        </div>
        : null
      }

    </div >
  );
};

export default SLists;