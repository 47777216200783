import React, { useEffect, useState } from "react";
import "./ShowSongs.scss";
import { IoCloseCircle, IoPencil, IoDesktopOutline } from "react-icons/io5";
import moment from "moment";
import RestClient from "../../../@core/restClient";
import SongFullScreen from "../UI/SongFullScreen";
import { useDeprecatedAnimatedState, useScroll } from "framer-motion";



const ShowSongs = (props: any) => {
  // const day = props.date.toLocaleString("uk-UA", { day: "2-digit" });
  // const month = props.date.toLocaleString("uk-UA", { month: "2-digit" });
  // const year = props.date.getFullYear();

  const date = moment(props.date).format("DD.MM.YYYY");

  const [ids, setIds] = useState<any[]>([]);
  const [selectedSongs, setSelectedSongs] = useState<any[]>([]);
  const [screenMode, setScreenMode] = useState(false);


  const songs = () => {
    const songIds = props.songs.map((a: any) => a.songId);
    console.log(songIds);
    console.log(selectedSongs);
    setIds(songIds);
    setScreenMode(true);

  };
  const closeFullScreen = () => {
    setScreenMode(false);
  };

  useEffect(() => {
    (async () => {
      try {
        const selectionSongs = await RestClient.getSongs(ids);
        console.log(selectionSongs);
        console.log(ids);
        setSelectedSongs(selectionSongs);
      } catch (error) {
        console.log("error");
      }
    })();

  }, [ids]);



  return (
    <div>
      <div className="show-slist">
        <div className="show-slist-title">
          <p>{props.name}</p>
          <p>{date}</p>
          <div className="show-slist-icons">
            <IoDesktopOutline className="show-slist-icons-item" onClick={songs} />
            <IoPencil className="show-slist-icons-item" onClick={() => props.editSelection(props.id, props.name, props.date, props.songs)} />
            <IoCloseCircle className="show-slist-icons-item" onClick={() => props.onDelete(props.id)} />
          </div>
        </div>
        <ol className="show-slist-list">
          {props.songs.map((el: any) =>
            <li className="show-slist-item" key={el.id}>{el.songTitle}<span>t-{el.songTempo}</span> </li>
          )}
        </ol>
      </div>
      {
        screenMode ?
          <div>
            <SongFullScreen selectedSongs={selectedSongs} closeFullScreen={closeFullScreen} />
          </div>
          : null
      }

    </div>
  );
};

export default ShowSongs;