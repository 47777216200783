import React from "react";
import Features from "../../components/widgets/features/Features";
import Hero from "../../components/widgets/Hero/Hero";
import "./Home.scss";

const Home = () => {
  return (
    <div className="home">
      <Hero />
      <Features />
    </div>
  );
};

export default Home;