import React, { useCallback, useEffect, useRef, useState } from "react";
import "./Header.scss";
import { IoEnter, IoSearchOutline, IoCloseCircleOutline, IoExitOutline } from "react-icons/io5";
import { useNavigate } from "react-router-dom";
import { Songbook } from "../../../@core/models";
import debounce from "lodash/debounce";
import RestClient from "../../../@core/restClient";
import LoginModal from "../dynamic/LoginModal/LoginModal";

type queryState = [
  query: string,
  setQuery: (query: string) => void
];

type SelectedSongbookIdState = [
  selectedSongbookId: number | null,
  setSelectedSongbookId: (selectedSongbookId: number | null) => void
];

type SongbooksState = [
  songbooks: Songbook[],
  setSongbooks: (songbooks: Songbook[]) => void
];

type IsOpenState = [
  isOpen: boolean,
  setIsOpen: (isOpen: boolean) => void
];

const Header = () => {

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const queryRef = useRef<any>();
  const [query, setQuery]: queryState = useState<string>("");
  const [selectedSongbookId, setSelectedSongbookId]: SelectedSongbookIdState = useState<number | null>(null);
  const [songbooks, setSongbooks]: SongbooksState = useState<Songbook[]>([]);
  const [isOpen, setIsOpen]: IsOpenState = useState(false);
  const navigate = useNavigate();
  const [loginModalShow, setLoginModalShow] = useState(false);
  const [person, setPerson] = useState(false);

  useEffect(function () {
    if (localStorage.getItem("User")) {
      setPerson(true);
    }
  }, [localStorage.getItem("User")]);

  const goHome = () => {
    queryRef.current.value = "";
    setQuery("");
    setSelectedSongbookId(null);
    setSongbooks([]);
    setIsOpen(false);
    navigate("/");
  };

  const deferredSetQueryHandler = useCallback(debounce(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setQuery(event.target.value);
    }, 500
  ), []);

  useEffect(function () {
    (async () => {
      if (queryRef.current !== document.activeElement ||
        queryRef.current.value !== query
      ) {
        return;
      }
      try {
        const response = await RestClient.getSongbookList(query);
        console.log("getSongbookList response", response);
        setSongbooks(response || []);
      } catch (error) {
        console.log(error);
      }
    })();
  }, [query, isOpen]);

  const selectSongbook = (songbook: Songbook) => {
    queryRef.current.value = songbook.title;
    setQuery(songbook.title);
    setSelectedSongbookId(songbook.id);
    setSongbooks([]);
    navigate(`/songbook/${songbook.id}`);
    setQuery("");
    setIsOpen(false);
    // setIsOpen(false); // todo: need to be discussed
  };
  // const submitSongbook = (e: any) => {
  //   e.stopPropagation();
  //   if (selectedSongbookId !== null) { // todo: check using incorrect id
  //     console.log(`navigate to /songbook/${selectedSongbookId}`);
  //     navigate(`/songbook/${selectedSongbookId}`);
  //     setQuery("");
  //     setIsOpen(false);
  //   } else {
  //     // todo: show notification
  //   }
  // };
  const openAgain = () => {
    queryRef.current.value = "";
    //setQuery("");
    //setSelectedSongbookId(null);
    setIsOpen(true);
    console.log("state of open", isOpen);
  };
  const goToCabinet = () => {
    navigate("/cabinet");
  };

  const openLoginModal = () => {
    setLoginModalShow(true);
  };
  const closeLoginModal = () => {
    setLoginModalShow(false);
  };

  useEffect(function () {
    console.log(loginModalShow);
  }, [loginModalShow]);






  return (
    <header className="row">
      <div className="col-8 col-md-3 mb-3">
        <div className="d-flex align-items-center">
          <a onClick={goHome}>
            <img src="/images/header-logo.png" className="img-logo" alt="logo" />
          </a>
          <div className="text-logo">
            <p className="title text-uppercase">
              Aryn-lyrics
            </p>
            <p className="sub-title">
              Твій збірник пісень
            </p>
          </div>
        </div>
      </div>
      <div className="col-12 col-md-6 order-3 order-md-2 mb-3">
        <div className="h-100 d-flex align-items-center">
          <div className="songbook-input-container">
            <input type="text"
              ref={queryRef}
              className="songbook-input"
              placeholder="Введіть назву збірника.."
              //onFocus={() => setIsOpen(true)}
              onChange={deferredSetQueryHandler}
              onClick={() => openAgain()}


            />
            <IoSearchOutline className="search" />
            {/* <IoEnter className="enter"
              onClick={submitSongbook}
            /> */}
            {songbooks?.length > 0 && isOpen &&
              <div className="select-container">
                <IoCloseCircleOutline className="close"
                  onClick={() => setIsOpen(false)}
                />
                {songbooks?.map((songbook: Songbook, index: number) => (
                  <div className="select-item"
                    key={index}
                    onClick={() => selectSongbook(songbook)}
                  >
                    {songbook.title}
                  </div>
                ))}
              </div>
            }
          </div>
        </div>
      </div>
      <div className="col-4 col-md-3 order-2 order-md-3 mb-3">
        <div className="h-100 d-flex justify-content-end align-items-center">
          {!person ?
            <button className="login btn-plain" onClick={openLoginModal}>
              Увійти
            </button>
            :
            <div className="logedIn">
              <div className="login-box">

                <button className="login btn-plain" onClick={goToCabinet}>
                  Кабінет
                </button>
                <div className="user-email">{JSON.parse(localStorage.getItem("User") || "{}")?.email}</div>
              </div>
              <IoExitOutline className="exit" onClick={() => { localStorage.clear(); setPerson(false); goHome(); }} />
            </div>

          }
        </div>
        {loginModalShow ?
          <div>
            <LoginModal closeModal={closeLoginModal} />
          </div> : null
        }
      </div>

    </header>
  );
};

export default Header;

