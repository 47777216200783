import React from "react";
import "./Error.scss";

const Error = () => {
  return (
    <div className="error d-flex justify-content-center text-align-center">
      <h2>
        Вибачте, але цієї сторінки не існує
      </h2>
    </div>
  );
};

export default Error;