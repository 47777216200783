import React, { useCallback, useEffect, useRef, useState, forwardRef, useImperativeHandle } from "react";
import "./SBooks.scss";
import "./../../components/widgets/UI/SelectUsers.scss";
import { useNavigate, Link } from "react-router-dom";
import {
  IoPencilOutline, IoTrash, IoEnter, IoArrowUpCircleSharp, IoCloseCircle
} from "react-icons/io5";
import RestClient from "../../@core/restClient";
import SelectUsers from "../../components/widgets/UI/SelectUsers";
//import "./SLists.scss";



const SBooks = () => {

  const [booksList, setBooksList]: any = useState([]);
  const [collaboratorsList, setCollaboratorsList] = useState<any[]>([]);
  const [editMode, setEditMode] = useState(false);
  const [btnSaveDisabled, setBtnSaveDisabled] = useState(true);
  const [modal, setModal] = useState(false);
  const [book, setBook] = useState<any>({ "id": 0, "ownerId": 0, "title": "", "public": true, "collaborators": [] });
  const [showBookInfo, setShowBookinfo] = useState(false);
  const [yesDelete, setYesDelete] = useState(false);

  const emptyRef = useRef();
  const titleRef = useRef();

  const titleHandler = (event: any) => {
    event.target.value !== "" ? setBtnSaveDisabled(false) : setBtnSaveDisabled(true);
    setBook({ ...book, title: event.target.value });
  };

  const radioHandler = (event: any) => {
    if (event.target.value === "public") setBook({ ...book, public: true });
    if (event.target.value === "private") setBook({ ...book, public: false });

  };


  const addCollaborators = function (collaborators: any) {
    console.log(collaborators);
    //setCollaboratorsList(collaborators);
    setBook({ ...book, collaborators: collaborators.map((i: any) => i.id) });

  };
  const saveBook = (book: any) => {
    console.log(book);
    setModal(false);
    if (!editMode) {
      try {
        (async () => {
          delete book.id;
          const result = await RestClient.addSongBook(book);
          console.log(result);
          //setIsNewGroupSent(true);

        })();
      } catch (error) {
        console.log(error);
      }
    } else {
      try {
        (async () => {

          const result = await RestClient.editSongBook(book);
          console.log(result);
          //setIsNewGroupSent(true);
          setEditMode(false);
        })();
      } catch (error) {
        console.log(error);
      }

    }
    if (emptyRef && emptyRef.current) (emptyRef.current as any).emptyCollaboratorsArray();

  };

  const hideCreateBox = () => {
    setModal(false);
    setEditMode(false);
    setBook({ ...book, "id": 0, "ownerId": 0, "title": "", "public": true, "collaborators": [] });
    setShowBookinfo(false);
  };

  const showCreateBox = () => {
    setModal(true);
  };

  const editBook = () => {
    setModal(true);
    setEditMode(true);
    setBtnSaveDisabled(false);
    setCollaboratorsList(book.collaborators);
  };

  const showInfo = (bookId: any) => {
    setShowBookinfo(true);

    try {
      (async () => {
        const currentBook = await RestClient.getSongbook(bookId);
        console.log(currentBook);
        setBook(currentBook);
      })();
    } catch (error) {
      console.log(error);
    }
  };

  const deleteBook = (bookId: any) => {
    try {
      (async () => {
        const bookDeleted = await RestClient.deleteSongBook(bookId);
        if (bookDeleted) {
          setBook({ ...book, "id": 0, "ownerId": 0, "title": "", "public": true, "collaborators": [] });
          setYesDelete(false);
          setShowBookinfo(false);

        }
      })();
    } catch (error) {
      console.log(error);
    }

  };

  const showDeleteModal = () => {
    setYesDelete(true);
  };

  const hideDeleteModal = () => {
    setYesDelete(false);
  };

  useEffect(function () {
    (async () => {
      try {
        const id = JSON.parse(localStorage.getItem("User") || "{}")?.id;
        if (!id) {
          console.log("incorrect User id");
          return;
        }
        setBook({ ...book, ownerId: id });
        const sbooks = await RestClient.getSongBooks(id);
        setBooksList(sbooks);
        console.log(sbooks);
      } catch (error) {
        console.log(error);
      }
    })();
  }, [modal, yesDelete]);

  useEffect(() => {

    //const content = document.getElementById("sbooks");
    if (modal) {
      //content.style.position = "fixed";
      document.body.style.position = "fixed";

    }
    if (!modal) {
      //content.style.position = "relative";
      document.body.style.position = "relative";
    }
  }, [modal]);

  useEffect(() => {

    //const content = document.getElementById("sbooks");
    if (yesDelete) {
      //content.style.position = "fixed";
      document.body.style.position = "fixed";

    }
    if (!yesDelete) {
      //content.style.position = "relative";
      document.body.style.position = "relative";
    }
  }, [yesDelete]);



  return (

    <div className="sbooks" id="sbooks">
      <button className="btn-mcolor" onClick={showCreateBox}>Створити новий збірник</button>
      <div className="sbooks-all" >
        {booksList.map((book: any) =>
          <div className="sbooks-one sb-active" key={book.id}>
            <div onClick={() => showInfo(book.id)}>{book.title}</div>
            <div className="sbooks-one-icons">
              <Link to={`/songbook/${book.id}`}>
                <div className="icon" ><IoEnter /></div>
              </Link>
            </div>
          </div>
        )}
      </div>
      {modal ?
        <div className="create-book">
          <div className="create-book-box">
            <p className="create-box-label">Введіть назву збірника</p>

            <input type="text" className="inputs" onChange={titleHandler} value={book.title} />
            <p className="create-box-label"> Виберіть статус</p>

            <div className="attributes-radio" onChange={radioHandler}>
              <input type="radio" id="public" name="status" value="public" defaultChecked={book.public === true} />
              <label htmlFor="public">Загальний</label>
              <input type="radio" id="private" name="status" className="itemcheck" value="private" defaultChecked={book.public === false} />
              <label htmlFor="private">Приватний</label>
              <div className="attributes-radio-item" >
              </div>
              <div className="attributes-radio-item">
              </div>
            </div>

            <div>
              <p className="create-box-label">Додайте користувачів</p>
              <SelectUsers passingCollaboratorsArray={addCollaborators} ref={emptyRef} collaboratorsList={collaboratorsList} editMode={editMode} />

            </div>
            <div className="btn-box">
              <button className={`${btnSaveDisabled ? "create-box-btn btn-mcolor-inactive" : "create-box-btn btn-mcolor"}`} disabled={btnSaveDisabled} onClick={() => saveBook(book)}>зберегти</button>
              <button className="create-box-btn btn-plain" onClick={hideCreateBox}>Скасувати</button>
            </div>
          </div>
        </div>
        : null}

      {showBookInfo ?
        <div className="attributes">
          <div className="btn-box">
            <button className="create-box-btn btn-plain" onClick={editBook}>Редагувати</button>
            <button className="create-box-btn btn-plain" onClick={showDeleteModal}>Видалити</button>
          </div>

          <p className="attributes-title"> Назва: <span>{book.title}</span></p>

          <p className="attributes-status">Статус:<span>  {book.public === true ? "загальний" : "приватний"}  </span></p>
          <p className="attributes-users">Користувачі з правом редагування:</p>
          <div className="attributes-users-added">
            {book.collaborators.map((user: any) =>
              <p className="attributes-users-added-item" key={user.id}>{user.firstName} {user.lastName}</p>
            )}

          </div>
        </div>
        : null}
      {yesDelete ?
        <div className="delete">
          <div className="delete-box">
            <p className="delete-box-message">Видалити <span>{book.title}</span>? Всі пісні також будуть видалені.</p>
            <div className="delete-box-btns">
              <button className="btn-mcolor delete-box-btn " onClick={() => deleteBook(book.id)}>Так</button>
              <button className="btn-plain delete-box-btn " onClick={hideDeleteModal}>Ні</button>
            </div>
          </div>
        </div>
        : null
      }
    </div>
  );
};

export default SBooks;