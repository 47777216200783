import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import "./Cabinet.scss";
import SBooks from "./SBooks";
import SLists from "./SLists";
import PersonalData from "./PersonalData";
import { IoCloseCircle } from "react-icons/io5";




const Cabinet = (props: any) => {

  const navigate = useNavigate();
  const goBack = () => navigate(-1);

  const [numberTab, setNumberTab] = useState(1);

  return (
    <div className="cabinet">
      <div className="cabinet-title">
        <h2 className="">Мій кабінет</h2>
        <IoCloseCircle onClick={goBack} className="cabinet-title-close" />
      </div>
      <div className="cabinet-btns">
        <div onClick={() => setNumberTab(0)} className={`tab-title ${numberTab === 0 ? "current" : ""} `}>
          <div>Збірники</div>
        </div>
        <div onClick={() => setNumberTab(1)} className={`tab-title ${numberTab === 1 ? "current" : ""} `}>
          <div>Підбірки</div>
        </div>

      </div>
      <div className="tabs">
        {numberTab === 0 && <SBooks />}
        {numberTab === 1 && <SLists />}
      </div>
    </div >
  );
};

export default Cabinet;