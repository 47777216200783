import React, { useState } from "react";
import { EditorState, convertToRaw, convertFromRaw } from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import "../../../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import "./SongEditor.scss";
import { DEFAULT_ROW } from "../../../@core/constants";

interface SongEditorProps {
  row: string;
  onRowChange: (row: string) => void;
}

type EditorStateInterface = [
  editorState: EditorState,
  setEditorState: (editorState: EditorState) => void
];

const SongEditor = ({ row, onRowChange }: SongEditorProps) => {

  const [editorState, setEditorState]: EditorStateInterface
    = useState<EditorState>(EditorState.createWithContent(
      convertFromRaw(JSON.parse(row || DEFAULT_ROW))
    ));

  const onEditorStateChange = (editorState: EditorState) => {
    setEditorState(editorState);
    const row = convertToRaw(editorState.getCurrentContent());
    onRowChange(JSON.stringify(row));
  };

  return (
    <div className="song-editor">
      <Editor
        wrapperClassName="wrapper"
        //toolbarClassName="toolbar"
        editorClassName="editor"
        editorState={editorState}
        onEditorStateChange={onEditorStateChange}
      />
    </div>
  );
};

export default SongEditor;
